// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "t_rV d_bD";
export var storyRowWrapper = "t_hx d_hx d_bK";
export var storyLeftWrapper = "t_rW d_bz d_bP";
export var storyWrapperFull = "t_rX d_cD";
export var storyWrapperFullLeft = "t_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "t_mv d_hy";
export var storyLeftWrapperCenter = "t_rY d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "t_rZ d_hF";
export var storyHeader = "t_r0 d_hD d_w d_cs";
export var storyHeaderCenter = "t_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "t_hB d_hB d_by d_dw";
export var storyBtnWrapper = "t_r1 d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "t_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "t_r2 d_fg d_Z";
export var imageWrapperFull = "t_r3 d_w d_H d_bf d_Z";
export var SubtitleSmall = "t_qd w_qd w_r6 w_sj";
export var SubtitleNormal = "t_qf w_qf w_r6 w_sk";
export var SubtitleLarge = "t_qg w_qg w_r6 w_sl";
export var textLeft = "t_dv";
export var textCenter = "t_dw";
export var textRight = "t_dx";