// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rB d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_rC d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_rD d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_rF d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_rG d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_rH d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_rJ d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_rK w_rK";
export var heroExceptionNormal = "s_rL w_rL";
export var heroExceptionLarge = "s_rM w_rM";
export var Title1Small = "s_rN w_rN w_r6 w_r7";
export var Title1Normal = "s_rP w_rP w_r6 w_r8";
export var Title1Large = "s_rQ w_rQ w_r6 w_r9";
export var BodySmall = "s_rR w_rR w_r6 w_sq";
export var BodyNormal = "s_rS w_rS w_r6 w_sr";
export var BodyLarge = "s_rT w_rT w_r6 w_ss";